import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Layout } from "../../components/Layout/layout";
import FormSteps from "../../components/organisms/FormSteps";
import Loading from "../../components/atoms/Loading";
import usePrepareFormData from "../../hooks/usePrepareFormData";
import ApplicationContext from "../../context/ApplicationContext";
import { FormContextProvider } from "../../context/FormContext";
import { setCookies } from "../../components/utils/cookies";

const FormTemplate = ({ pageContext, location }) => {
  const { loading, ...applicationData } = usePrepareFormData(pageContext.json);
  const [structure, setStructure] = useState(applicationData.structure)
  const source = location?.state?.source;
  const { language } = useI18next();

  useEffect(() => {
    setCookies("testAB", {testAB: pageContext.testAB});
    hideAddons()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideAddons = () => {
    const hideAddons = structure.steps[0].groups
      .map(group => group.name === "addons" ? { ...group, hide: true } : group);
    
    // remove last line
    hideAddons.splice(hideAddons.length - 2, 1);
    
    setStructure((prevState) => ({
      ...prevState,
      steps: prevState.steps.map((step, index) => {
        if (index === 0) {
          return {
            ...step,
            groups: hideAddons,
          };
        }
        return step;
      }),
    }));
  }

  return (
    <Layout
      headerTitle={pageContext.title[language] ? pageContext.title[language] : undefined}
      hideMenuButton={false}
      headerBigMaxWidth
      footerBigMaxWidth
      disclaimerBigMaxWidth>
      <ApplicationContext.Provider value={applicationData}>
        {loading ? (
          <Loading full />
        ) : (
          <FormContextProvider
            form={applicationData.form}
            structure={structure}>
            <FormSteps
              form={applicationData.form}
              structure={structure}
              formDetails={applicationData.formDetails}
              orderCookie={applicationData.orderCookie}
              stepNames={applicationData.stepNames}
              formName={applicationData.formName}
              formType={pageContext.name.toLowerCase()}
              source={source}
              isFormToEdit={applicationData.isFormToEdit}
            />
          </FormContextProvider>
        )}
      </ApplicationContext.Provider>
    </Layout>
  );
};

export default FormTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;